import * as zod from 'zod';

import { BaseWeldMachineState } from '../../shared/types/BaseWeldMachineState';
import { GasType, WireSize, WireType } from '../implementation/constants';

export const WeldMachineMode = zod.enum(['pulse', 'constantVoltage', 'unset']);

export type WeldMachineMode = zod.infer<typeof WeldMachineMode>;

/**
 * Miller weld machine state
 *
 * NOTE (MW, 24/11/05) At present, copy almost entire modbus into the equipment state. Even when the items appear to be duplicates (e.g. presetWFS). Do this to confirm that they are the same and not leave anything out.
 */
export const MillerWeldMachineState = BaseWeldMachineState.extend({
  kind: zod.literal('MillerWeldMachine'),
  isDryRunMode: zod.boolean(), // Inverse of coils.weldDisable

  // Coils: R+W bools. Use these to start/stop processes
  coils: zod.object({
    // From Coils
    weldDisable: zod.boolean(), // Weld disable does not actually work. However, we want to track this because - if it is active - then the weld settings won't actually work.
    contactorOn: zod.boolean(),
    gasEnable: zod.boolean(),
    motorForward: zod.boolean(),
    motorRetract: zod.boolean(),
    touchSenseEnable: zod.boolean(),
  }),

  // DiscreteInputs: Read-only Status indicators
  discreteInputs: zod.object({
    arcDetected: zod.boolean(),
    contactorStatus: zod.boolean(),
    gasStatus: zod.boolean(),
    feedForwardStatus: zod.boolean(),
    feedRetractStatus: zod.boolean(),
    touchSenseEnabled: zod.boolean(),
    wireTouched: zod.boolean(),
    weldStateStatus: zod.boolean(),
    synergicProcess: zod.boolean(),
    errorActive: zod.boolean(), // Error state may not be consistent with what comes up on machine.
    pSConnected: zod.boolean(),
    ready: zod.boolean(),
  }),

  // Input registers: Read-only values
  inputRegisters: zod.object({
    // Actual. Table 3-2
    actualWFS: zod.number(), // IPM. 0-780
    actualArcVoltage: zod.number(), // Volt conversion. 301 => 30.1V
    actualArcCurrent: zod.number(), // AMPS [0,999]
    actualGasFlow: zod.number(), // Does not appear to be fully implemented? Units were "TBD"
    actualRawHeat: zod.number(), // 78 => 7.8 kJ/s
    actualHeatInput: zod.number(), // 175 => 1.75 kJ/mm
    // Presets: Values as set on machine (either programmatically/on-bot or on machine, elsewhere)
    presetWFS: zod.number(), // IPM. 50-780
    presetVoltage: zod.number(), // Volt conversion. 301 => 30.1V
    presetArcLength: zod.number(), // [-10,10] => [0,200]
    presetArcControl: zod.number(), // [0,50]
    memorySlot: zod.number().int(),
    activeProcess: WeldMachineMode, // Table 3-4
    activeWireType: WireType, // Table 3-5
    activeWireSize: WireSize, // Table 3-6
    activeGasType: GasType, // Table 3-7
    errorCode: zod.number().int(), // Errors on machine may not show up here.
  }),

  holdingRegisters: zod.object({
    presetWFS: zod.number(), // IPM. 50-780
    presetVoltage: zod.number(), // Volt conversion. 301 => 30.1V
    presetArcLength: zod.number(), // [-10,10] => [0,200]
    presetArcControl: zod.number(), // [0,50]
    selectMemorySlot: zod.number().int(),
    weldProcess: WeldMachineMode, // Table 3-4
    weldWireType: WireType, // Table 3-5
    weldWireSize: WireSize, // Table 3-6
    weldGasType: GasType, // Table 3-7
    weldTravelSpeed: zod.number(), // mm/sec. 12 => 1.2 mm/sec
  }),
});

export type MillerWeldMachineState = zod.infer<typeof MillerWeldMachineState>;
