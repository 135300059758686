import { interpolateCartesianPoses } from '@sb/geometry/CartesianPose';
import { cartesianDistance } from '@sb/geometry/CartesianPosition';
import { makeNamespacedLog } from '@sb/log';
import type {
  ArmTarget,
  PoseArmTarget,
  TCPOffsetOption,
} from '@sb/motion-planning';
import type {
  EquipmentStateItem,
  RoutineContext,
  StepFailure,
} from '@sb/routine-runner';
import { FailureKind } from '@sb/routine-runner';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';
import type WaypointStep from '@sb/routine-runner/Step/Waypoint/Step';
import { wait } from '@sb/utilities';
import type { NonEmptyArray } from '@sb/utilities/src/types';

import { WeldArguments } from './Arguments';
import { WeldVariables } from './Variables';

const log = makeNamespacedLog('WeldStep');

export class WeldStep extends Step<WeldArguments, WeldVariables> {
  public static areSubstepsRequired = true;

  public static Arguments = WeldArguments;

  public static Variables = WeldVariables;

  public substeps: Array<Step<object, object>> = [];

  protected initializeVariableState(): void {
    const { completedWeldCount = 0 } = this.variablesForInitialization;

    this.variables = {
      completedWeldCount,
      currentActivity: 'none',
      approachMovementActivity: 'none',
      weldMovementActivity: 'none',
    };
  }

  private motionApproach?: ReturnType<RoutineContext['doMotion']>;

  private motionWeld?: ReturnType<RoutineContext['doMotion']>;

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    log.info('_play', 'Playing Weld step');

    const { weldMachine, torch } = this.activeEquipment;

    if (weldMachine == null) {
      fail({
        failure: {
          kind: FailureKind.WeldFailure,
        },
        failureReason: 'Weld machine not found',
      });

      return;
    }

    if (torch == null) {
      fail({
        failure: {
          kind: FailureKind.WeldFailure,
        },
        failureReason: 'Weld torch not found',
      });

      return;
    }

    this.setRoutineContextTCPOffsetOption();

    if (this.substeps.length === 0) {
      fail({
        failure: {
          kind: FailureKind.WeldFailure,
        },
        failureReason: 'No waypoints found',
      });

      return;
    }

    if (this.substeps.length < 2) {
      log.warn('_play', 'Need at least two waypoints for welds');

      return;
    }

    // Get the approach target and return early if it fails
    const [approachTarget] = (await this.getArmTargets({
      substeps: [this.substeps[0]] as WaypointStep[],
      fail,
    })) ?? [undefined];

    if (approachTarget === undefined) {
      return;
    }

    this.setVariable('currentActivity', 'approaching');

    const approachCompleted = await this.moveToPosition({
      fail,
      armTarget: approachTarget,
    });

    if (!approachCompleted) {
      log.warn('_play', 'Approach movement failed. Returning.', {
        variables: this.variables,
      });

      return;
    }

    this.setVariable('currentActivity', 'settingWeldParameters');
    await this.setWeldMachineParameters({ weldMachine, fail });

    if (!this.args.stitching.enabled) {
      await this.performContinuousWeld({ weldMachine, fail });
    } else {
      await this.performStitchedWeld({ weldMachine, fail });
    }

    this.setVariable('currentActivity', 'none');

    this.setVariable(
      'completedWeldCount',
      this.variables.completedWeldCount + 1,
    );
  }

  /**
   * Perform a continuous weld without stitching
   */
  private async performContinuousWeld({
    weldMachine,
    fail,
  }: {
    weldMachine: EquipmentStateItem;
    fail: (failure: StepFailure) => void;
  }): Promise<void> {
    const waypoints = this.substeps as WaypointStep[];

    const targets = await this.getArmTargets({
      substeps: waypoints.slice(1) as WaypointStep[],
      fail,
    });

    if (targets === undefined) {
      log.error('performContinuousWeld', 'No targets found. Returning.');

      return;
    }

    this.setVariable('currentActivity', 'startArc');
    await this.startWeldMachine({ weldMachine, fail });

    await wait(this.args.arcStartTime);

    this.setVariable('currentActivity', 'movingDuringWeld');

    const weldCompleted = await this.moveDuringWeld({
      targets,
      fail,
    });

    // If weld doesn't complete, stop arc right away. No crater filling.
    if (weldCompleted) {
      this.setVariable('currentActivity', 'craterFilling');

      await wait(this.args.craterFillTime);
    }

    this.setVariable('currentActivity', 'stopArc');
    await this.stopWeldMachine({ weldMachine });
  }

  /**
   * Perform a stitched weld, alternating between welding and not welding
   */
  private async performStitchedWeld({
    weldMachine,
    fail,
  }: {
    weldMachine: EquipmentStateItem;
    fail: (failure: StepFailure) => void;
  }): Promise<void> {
    const waypoints = this.substeps as WaypointStep[];

    if (waypoints.length < 2) {
      log.warn(
        'performStitchedWeld',
        'Need at least two waypoints for stitched weld',
      );

      return;
    }

    // Process each waypoint segment (from point A to point B)
    for (let i = 0; i < waypoints.length - 1; i += 1) {
      const startWaypoint = waypoints[i];
      const endWaypoint = waypoints[i + 1];

      log.debug(
        'performStitchedWeld',
        `Processing waypoint segment ${i} to ${i + 1}`,
      );

      // Get the start and end targets
      const startTarget = await startWaypoint.getArmTarget({
        effectiveTCPOption: this.getTCPOffsetOption(),
        parentCompletedCount: this.variables.completedWeldCount,
      });

      if ('failure' in startTarget) {
        fail(startTarget);

        return;
      }

      const endTarget = await endWaypoint.getArmTarget({
        effectiveTCPOption: this.getTCPOffsetOption(),
        parentCompletedCount: this.variables.completedWeldCount,
      });

      if ('failure' in endTarget) {
        fail(endTarget);

        return;
      }

      // throw error if startTarget does not have a pose
      if (!('pose' in startTarget) || !('pose' in endTarget)) {
        throw new Error('Start target does not have a pose');
      }

      // Split this segment into stitch and space sub-segments
      const subTargets = this.generateSegments(startTarget, endTarget);

      // Process each sub-segment
      for (let j = 0; j < subTargets.length; j += 1) {
        const isWeldTarget = j % 2 === 0; // even segments are weld segments
        const target = subTargets[j];

        log.debug(
          'performStitchedWeld',
          `  Processing sub-segment ${j}, weld=${isWeldTarget}`,
        );

        let movementCompleted: boolean;

        if (isWeldTarget) {
          // Start welding for this segment
          this.setVariable('currentActivity', 'startArc');
          await this.startWeldMachine({ weldMachine, fail });
          await wait(this.args.arcStartTime);

          // Move during this segment
          this.setVariable('currentActivity', 'stitching');

          movementCompleted = await this.moveDuringWeld({
            targets: [target],
            fail,
          });
        } else {
          this.setVariable('currentActivity', 'movingToNextStitch');

          movementCompleted = await this.moveToPosition({
            fail,
            armTarget: target,
          });
        }

        if (!movementCompleted) {
          // Stop welding if movement failed
          this.setVariable('currentActivity', 'stopArc');
          await this.stopWeldMachine({ weldMachine });

          return;
        }

        if (isWeldTarget) {
          // Stop welding after this segment
          this.setVariable('currentActivity', 'craterFilling');
          await wait(this.args.craterFillTime);
          this.setVariable('currentActivity', 'stopArc');
          await this.stopWeldMachine({ weldMachine });
        }
      }
    }
  }

  /**
   * Generate stitch and space segments for a weld path
   */
  private generateSegments(
    startTarget: ArmTarget & PoseArmTarget,
    endTarget: ArmTarget & PoseArmTarget,
  ): ArmTarget[] {
    const stitchLength = this.args.stitching.stitch;
    const spaceLength = this.args.stitching.space;
    const totalDistance = cartesianDistance(startTarget.pose, endTarget.pose);

    const targets: ArmTarget[] = [];
    let currentDistance = 0;
    const MINIMUM_STITCH_DISTANCE = 0.005; // 5 mm, adjust as needed

    // initialize spaceEnd to startTarget
    let spaceEnd = startTarget;

    while (currentDistance < totalDistance - MINIMUM_STITCH_DISTANCE) {
      const stitchEnd = {
        ...this.interpolate(
          startTarget,
          endTarget,
          Math.min((currentDistance + stitchLength) / totalDistance, 1.0),
        ),
        motionKind: 'line' as const, // hardcoded for now
      };

      targets.push(stitchEnd);

      log.info('generateSegments', 'Stitch segment', {
        start: {
          x: spaceEnd.pose.x,
          y: spaceEnd.pose.y,
          z: spaceEnd.pose.z,
        },
        end: {
          x: stitchEnd.pose.x,
          y: stitchEnd.pose.y,
          z: stitchEnd.pose.z,
        },
      });

      currentDistance += stitchLength;

      // If we've reached the end, we're done
      if (currentDistance >= totalDistance) break;

      // Create space segment
      spaceEnd = {
        ...this.interpolate(
          startTarget,
          endTarget,
          Math.min((currentDistance + spaceLength) / totalDistance, 1.0),
        ),
        motionKind: 'line' as const, // hardcoded for now
      };

      targets.push(spaceEnd);

      log.info('generateSegments', 'Space segment', {
        start: {
          x: stitchEnd.pose.x,
          y: stitchEnd.pose.y,
          z: stitchEnd.pose.z,
        },
        end: {
          x: spaceEnd.pose.x,
          y: spaceEnd.pose.y,
          z: spaceEnd.pose.z,
        },
      });

      currentDistance += spaceLength;
    }

    if (currentDistance < totalDistance) {
      // replace last space target with end target
      // this happens when the last space is less than MINIMUM_STITCH_DISTANCE
      targets.pop();
      targets.push(endTarget);
    }

    return targets;
  }

  /**
   * Interpolate between two arm targets by a given fraction
   */
  private interpolate(
    start: ArmTarget & PoseArmTarget,
    end: ArmTarget & PoseArmTarget,
    fraction: number,
  ): ArmTarget & PoseArmTarget {
    return {
      ...start,
      pose: interpolateCartesianPoses(start.pose, end.pose, fraction),
    };
  }

  public async _stop(): Promise<void> {
    log.info('_stop', 'Stopping Weld step');

    const { weldMachine } = this.activeEquipment;

    if (weldMachine == null) {
      log.info('_stop', 'No weld machine found. Nothing to stop.');

      return;
    }

    this.setVariable('currentActivity', 'stopArc');
    await this.stopWeldMachine({ weldMachine });

    if (this.motionApproach != null) {
      this.motionApproach.emit('cancel');
    }

    if (this.motionWeld != null) {
      this.motionWeld.emit('cancel');
    }

    this.setVariable('currentActivity', 'none');
  }

  public async _pause(): Promise<void> {
    log.info('_pause', 'Pausing Weld step');

    const { weldMachine } = this.activeEquipment;

    if (weldMachine == null) {
      log.info('_pause', 'No weld machine found. Nothing to pause.');

      return;
    }

    await this.stopWeldMachine({ weldMachine });

    if (this.motionApproach != null) {
      this.motionApproach.emit('pause');
    }

    if (this.motionWeld != null) {
      this.motionWeld.emit('pause');
    }
  }

  public async _resume(): Promise<void> {
    log.info('_resume', 'Resuming Weld step');

    const { weldMachine } = this.activeEquipment;

    if (weldMachine == null) {
      log.info('_resume', 'No weld machine found. Nothing to resume.');

      return;
    }

    // Main question: do we need to be starting weld machine again once approach is complete?
    if (this.motionApproach != null) {
      this.motionApproach.emit('resume');
      this.setVariable('approachMovementActivity', 'moving');
    }

    if (this.motionWeld != null) {
      this.setVariable('currentActivity', 'startArc');

      await this.startWeldMachine({ weldMachine });

      this.motionWeld.emit('resume');
      this.setVariable('weldMovementActivity', 'moving');
      this.setVariable('currentActivity', 'movingDuringWeld');
    }
  }

  private get activeEquipment(): {
    weldMachine: EquipmentStateItem | undefined;
    torch: EquipmentStateItem | undefined;
  } {
    const allEquipment = this.routineContext.equipment.getEquipmentState();

    const weldMachine = allEquipment.find(
      (equipment) => equipment.id === this.args.selectedMachineID,
    );

    const torch = allEquipment.find(
      (equipment) => equipment.id === this.args.selectedTorchID,
    );

    return { weldMachine, torch };
  }

  private getTCPOffsetOption(): TCPOffsetOption {
    const { torch } = this.activeEquipment;

    if (torch == null) {
      throw new Error('Torch not found');
    }

    if (this.args.selectedTorchID == null) {
      throw new Error('Selected torch ID is null');
    }

    // TCP Offset option a bit of a hack. May need to revisit the indexing once we add dedicated torches, not just custom grippers.
    // See https://github.com/standardbots/sb/blob/540d718e5d2bbe3226ea9b4128b9f49392bfc6ef/libs/integrations/implementations/CustomGripper/frontend.tsx#L30
    return `ee-${torch.state.kind}-0`;
  }

  private setRoutineContextTCPOffsetOption(): void {
    const tcpOffset = this.getTCPOffsetOption();
    this.routineContext.setTCPOffsetOption(tcpOffset);
  }

  private async moveToPosition({
    fail,
    armTarget,
  }: {
    fail: (failure: StepFailure) => void;
    armTarget: ArmTarget;
  }): Promise<boolean> {
    log.info('moveToPosition', 'Moving to position');
    // Weld does not observe push mode: should not be pushing up against object.
    // Also, ros2 does not support push at this time (2025-02-19).
    let completed = false;

    const motion = this.routineContext.doMotion({
      request: {
        targets: [armTarget] as NonEmptyArray<ArmTarget>,
      },
      speedProfiles: [this.args.approachSpeedProfile],
      pushUntilCollision: false,
      pushMode: false,
      stepID: this.id,
    });

    motion.on('requestingPlan', () => {
      // Only checking previous state for first few states because we could be in a play/pause/resume flow.
      if (this.variables.approachMovementActivity === 'none') {
        this.setVariable('approachMovementActivity', 'requestingPlan');
      }
    });

    motion.on('planning', () => {
      if (this.variables.approachMovementActivity === 'requestingPlan') {
        this.setVariable('approachMovementActivity', 'planning');
      }
    });

    motion.on('beginMotion', () => {
      this.setVariable('approachMovementActivity', 'moving');
    });

    motion.on('pause', () => {
      this.setVariable('approachMovementActivity', 'paused');
    });

    motion.on('complete', () => {
      log.debug('moveToApproachPosition', 'Approach motion complete');

      completed = true;
    });

    motion.on('cancelled', () => {
      this.setVariable('approachMovementActivity', 'none');
    });

    motion.on('failure', (failure) => {
      this.setVariable('approachMovementActivity', 'none');
      fail(failure);
    });

    this.motionApproach = motion;

    try {
      await motion.race('failure', 'complete', 'cancelled');
    } finally {
      this.setVariable('approachMovementActivity', 'none');
      motion.removeAllListeners();

      delete this.motionApproach;
    }

    return completed;
  }

  /**
   * Handles movement one set of targets at a time.
   */
  private async moveDuringWeld({
    targets,
    fail,
  }: {
    targets: ArmTarget[];
    fail: (failure: StepFailure) => void;
  }): Promise<boolean> {
    let completed = false;

    log.info('moveDuringWeld', 'Moving during weld iteration');

    if (targets.length === 0) {
      log.debug('moveDuringWeld', 'No targets found. Returning.');

      return completed;
    }

    // Weld does not observe push mode: should not be pushing up against object.
    // Also, ros2 does not support push at this time (2025-02-19).
    const motion = this.routineContext.doMotion({
      request: {
        targets: targets as NonEmptyArray<ArmTarget>,
      },
      speedProfiles: targets.map(() => this.args.weldTravelSpeedProfile),
      pushUntilCollision: false,
      pushMode: false,
      stepID: this.id,
    });

    motion.on('requestingPlan', () => {
      if (this.variables.weldMovementActivity === 'none') {
        this.setVariable('weldMovementActivity', 'requestingPlan');
      }
    });

    motion.on('planning', () => {
      if (this.variables.weldMovementActivity === 'requestingPlan') {
        this.setVariable('weldMovementActivity', 'planning');
      }
    });

    motion.on('beginMotion', () => {
      this.setVariable('weldMovementActivity', 'moving');
    });

    motion.on('pause', () => {
      this.setVariable('weldMovementActivity', 'paused');
    });

    motion.on('complete', () => {
      log.debug('moveDuringWeld', 'Welding motion complete');

      completed = true;
    });

    motion.on('cancelled', () => {
      this.setVariable('weldMovementActivity', 'none');
    });

    motion.on('failure', (failure) => {
      this.setVariable('weldMovementActivity', 'none');
      fail(failure);
    });

    this.motionWeld = motion;

    try {
      await motion.race('failure', 'complete', 'cancelled');
    } finally {
      this.setVariable('weldMovementActivity', 'none');
      motion.removeAllListeners();

      delete this.motionWeld;
    }

    return completed;
  }

  /**
   * Stop the current weld machine.
   *
   * Weld stop commands may be sent even if welding hasn't started yet.
   */
  private async stopWeldMachine({
    weldMachine,
  }: {
    weldMachine: EquipmentStateItem;
  }): Promise<void> {
    switch (weldMachine.kind) {
      case 'MillerWeldMachine':
        await this.stopMillerWeldMachine();
        this.setVisualizerWeldArcFlashEffect(false);

        break;
      case 'EsabWeldMachine':
        log.error('stopWeldMachine', 'ESAB weld machine not supported');

        break;
      case 'WeldMachine':
        log.error('stopWeldMachine', 'Generic weld machine not supported');

        break;
      default:
        log.error(
          'stopWeldMachine',
          `Unknown weld machine type: ${weldMachine.kind}`,
        );

        break;
    }
  }

  private async startWeldMachine({
    weldMachine,
    fail,
  }: {
    weldMachine: EquipmentStateItem;
    fail?: (failure: StepFailure) => void;
  }): Promise<void> {
    let failureReason: string;

    switch (weldMachine.kind) {
      case 'MillerWeldMachine':
        await this.startMillerWeldMachine();
        this.setVisualizerWeldArcFlashEffect(true);
        break;
      case 'EsabWeldMachine':
        failureReason = 'ESAB weld machine not supported';

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
      case 'WeldMachine':
        failureReason = 'Generic weld machine not supported';

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
      default:
        failureReason = `Unknown weld machine type: ${weldMachine.kind}`;

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
    }
  }

  private async setWeldMachineParameters({
    weldMachine,
    fail,
  }: {
    weldMachine: EquipmentStateItem;
    fail?: (failure: StepFailure) => void;
  }): Promise<void> {
    let failureReason: string;

    switch (weldMachine.kind) {
      case 'MillerWeldMachine':
        await this.setMillerWeldParameters();
        break;
      case 'EsabWeldMachine':
        failureReason = 'ESAB weld machine not supported';

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
      case 'WeldMachine':
        failureReason = 'Generic weld machine not supported';

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
      default:
        failureReason = `Unknown weld machine type: ${weldMachine.kind}`;

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
    }
  }

  private async stopMillerWeldMachine(): Promise<void> {
    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'stopArc',
      },
    });
  }

  private async startMillerWeldMachine(): Promise<void> {
    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'startArc',
      },
    });
  }

  private async setMillerWeldParameters(): Promise<void> {
    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'setWeldParameters',
        weldTravelSpeed: 0, // This is disregarded by machine. Just set it like this for now.
        ...this.args.millerWeldParameters,
      },
    });
  }

  /**
   * Get the arm targets for the given substeps.
   *
   * Fancy typing ensures the output array length matches the input array length.
   */
  private async getArmTargets<T extends Array<WaypointStep>>({
    substeps,
    fail,
  }: {
    substeps: T;
    fail: (failure: StepFailure) => void;
  }): Promise<{ [K in keyof T]: ArmTarget } | undefined> {
    const targets: ArmTarget[] = [];

    for (let i = 0; i < substeps.length; i += 1) {
      const substep = substeps[i];

      if (substep.getStepKind() !== 'Waypoint') {
        // This is a dev error. Will need to update once we add additional flourishes.
        throw new Error(
          `Substep is not a waypoint: kind=${substep.getStepKind()}`,
        );
      }

      const target = await (substep as WaypointStep).getArmTarget({
        effectiveTCPOption: this.getTCPOffsetOption(),
        parentCompletedCount: this.variables.completedWeldCount,
      });

      if ('failure' in target) {
        fail(target);

        return undefined;
      }

      targets.push(target);
    }

    return targets as { [K in keyof T]: ArmTarget };
  }

  // Enable/disable the weld flash effect on the torch in visualizer
  private setVisualizerWeldArcFlashEffect(isWelding: boolean) {
    // Get current weld machine state
    const { weldMachine } = this.activeEquipment;

    // TODO: fix the return type of activeEquipment.weldMachine to
    // specify that these are weld machine state items (then we don't need to check for 'isWelding' in the state)
    if (weldMachine && 'isWelding' in weldMachine.state) {
      weldMachine.state.isWelding = isWelding;
    }
  }
}
