import { Vector3 } from 'three';
import * as zod from 'zod';

export const CartesianPosition = zod.object({
  x: zod.number(),
  y: zod.number(),
  z: zod.number(),
});

export const identityTranslation = { x: 0, y: 0, z: 0 };

export type CartesianPosition = zod.infer<typeof CartesianPosition>;

export function convertPositionToVector3(pose: CartesianPosition) {
  return new Vector3(pose.x, pose.y, pose.z);
}

export function convertVector3ToPosition(vector: Vector3): CartesianPosition {
  return { x: vector.x, y: vector.y, z: vector.z };
}

export function vectorBetweenPoints(
  p1: CartesianPosition,
  p2: CartesianPosition,
): CartesianPosition {
  return {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
    z: p2.z - p1.z,
  };
}

export function crossProduct(
  v1: CartesianPosition,
  v2: CartesianPosition,
): CartesianPosition {
  return {
    x: v1.y * v2.z - v1.z * v2.y,
    y: v1.z * v2.x - v1.x * v2.z,
    z: v1.x * v2.y - v1.y * v2.x,
  };
}

export function normalize(v: CartesianPosition): CartesianPosition {
  const magnitude = Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);

  return {
    x: v.x / magnitude,
    y: v.y / magnitude,
    z: v.z / magnitude,
  };
}

export function scale(v: CartesianPosition, scaler: number): CartesianPosition {
  return {
    x: v.x * scaler,
    y: v.y * scaler,
    z: v.z * scaler,
  };
}

export function cartesianDistance(
  p1: CartesianPosition,
  p2: CartesianPosition,
): number {
  return Math.sqrt(
    (p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2 + (p2.z - p1.z) ** 2,
  );
}

// Linear interpolation of position between two positions by a given fraction
export function interpolateCartesianPositions(
  start: CartesianPosition,
  end: CartesianPosition,
  fraction: number,
): CartesianPosition {
  return {
    x: start.x + fraction * (end.x - start.x),
    y: start.y + fraction * (end.y - start.y),
    z: start.z + fraction * (end.z - start.z),
  };
}
