import type { DeviceKind } from '../types';

const END_EFFECTOR_KINDS: DeviceKind[] = [
  'OnRobot3FG15',
  'OnRobot2FG7',
  'OnRobot2FG14',
  'NoGripper',
  'OnRobotDualQuickChanger',
  'OnRobotScrewdriver',
  'OnRobotVGP20',
  'CustomGripper',
  'DHAG105145',
  'DHPGC30060',
  'DHCGI100170',
  'SchunkEGx',
  'OnRobotHexE',
];

const WELD_MACHINE_KINDS: DeviceKind[] = [
  'WeldMachine',
  'MillerWeldMachine',
  'EsabWeldMachine',
];

export const NETWORK_DEVICE: DeviceKind[] = ['ModbusTCPServer'];

export function isEndEffector(value: DeviceKind | { kind: DeviceKind }) {
  const kind = typeof value === 'string' ? value : value.kind;

  return END_EFFECTOR_KINDS.includes(kind);
}

export function isDynamicBase(value: DeviceKind | { kind: DeviceKind }) {
  const kind = typeof value === 'string' ? value : value.kind;

  return kind === 'EwellixLiftTLT';
}

export function isWeldMachine(value: DeviceKind | { kind: DeviceKind }) {
  const kind = typeof value === 'string' ? value : value.kind;

  return WELD_MACHINE_KINDS.includes(kind);
}
