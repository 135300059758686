import * as zod from 'zod';

// Common fields for all weld machine states
export const BaseWeldMachineState = zod.object({
  // This boolean determines whether we show the welding arc flash effect in the visualizer.
  // The visualizer doesn't have access to the routineRunnerState, so we plumb this through
  // via the WeldMachineState.
  isWelding: zod.boolean(),
});

export type BaseWeldMachineState = zod.infer<typeof BaseWeldMachineState>;
