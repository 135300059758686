import * as zod from 'zod';

import { RobotControlModeKindEnum } from '@sb/sb-robot-sdk/src/codegen/typescript-axios';

export const StandardBotsRO1State = zod.object({
  kind: zod.literal('StandardBotsRO1'),
  alias: zod.string().optional(),
  botId: zod.string().optional(),
  controlMode: zod.nativeEnum(RobotControlModeKindEnum).optional(),
  connected: zod.boolean(),
  healthy: zod.boolean(),
  build: zod.string().optional(),
});

export type StandardBotsRO1State = zod.infer<typeof StandardBotsRO1State>;
