import * as zod from 'zod';

const movementActivity = zod.enum([
  'none',
  'requestingPlan',
  'planning',
  'receivingPlan',
  'moving',
  'paused',
]);

export const WeldVariables = zod.object({
  completedWeldCount: zod.number().int().nonnegative(),
  currentActivity: zod.enum([
    'approaching',
    'settingWeldParameters',
    'startArc',
    'movingDuringWeld',
    'stitching',
    'movingToNextStitch',
    'craterFilling',
    'stopArc',
    'none',
  ]),
  // Keep these separate in order to distinguish movement during welding from approach.
  approachMovementActivity: movementActivity,
  weldMovementActivity: movementActivity,
});

export type WeldVariables = zod.infer<typeof WeldVariables>;
