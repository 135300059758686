import * as zod from 'zod';

import { BaseWeldMachineState } from '../../shared/types/BaseWeldMachineState';
import { WeldMachineMode } from '../../shared/types/WeldMachineMode';

export const WeldMachineState = BaseWeldMachineState.extend({
  kind: zod.literal('WeldMachine'),
  isReady: zod.boolean(),
  voltage: zod.number(),
  current: zod.number(),
  wireFeedSpeed: zod.number(),
  mode: WeldMachineMode,
  isTestMode: zod.boolean(),
});

export type WeldMachineState = zod.infer<typeof WeldMachineState>;
